import React, { Suspense, lazy } from 'react';
import { HashRouter as Router } from "react-router-dom";
import "./App.css";
import "./locales/config";

// 懒加载组件而不是直接导入
const Navbar = lazy(() => import("./components/navbar/Navbar"));
const Footer = lazy(() => import("./containers/Footer/Footer"));
const GlobalFloat = lazy(() => import("./containers/GlobalFloat/GlobalFloat"));
const RouterView = lazy(() => import("./router"));

// 创建简单的加载组件
const LoadingFallback = () => <div className="loading-fallback">Loading...</div>;

const App = () => {
  return (
    <Router>
      <div className="App">
        <div className="gradient__bg">
          <Suspense fallback={<LoadingFallback />}>
            <Navbar />
          </Suspense>
        </div>
        
        <Suspense fallback={<LoadingFallback />}>
          <GlobalFloat />
        </Suspense>
        
        <Suspense fallback={<LoadingFallback />}>
          <RouterView />
        </Suspense>
        
        <Suspense fallback={<LoadingFallback />}>
          <Footer />
        </Suspense>
      </div>
    </Router>
  );
};

export default App;