import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//配置中文的配置文件
import translation_zh from './zh.json';
//配置英文的配置文件
import translation_en from './en.json';
// 配置日文
import translation_ja from './ja.json';
const resources = {
  en: {
    translation: translation_en
  },
  zh: {
    translation: translation_zh
  },
  ja: {
    translation: translation_ja
  }
};
// 获取当前域名 如果是deepbi.com 则设置为zh 否则设置为en
const currentDomain = window.location.hostname;
let lng = 'zh';
if (currentDomain === 'deepbi.com') {
  lng = 'en';
}


i18n.use(initReactI18next).init({
  resources,
  lng: lng,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
