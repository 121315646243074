import React from 'react';
import './index.css';
// import 'antd/dist/antd.css';
import { createRoot } from 'react-dom/client';

import App from './App';
const browserLanguage = navigator.language || navigator.languages[0];
const lng = browserLanguage.startsWith('zh') ? 'zh' : 'en';
// console.log(lng,"lng");
// 如果是zh 则重定向到 deepbi.cn 否则重定向到 deepbi.com
if (lng === 'zh' && window.location.href.includes('deepbi.com')) {
  const path = window.location.hash; // 获取当前的hash路径
  
  window.location.href = `https://www.deepbi.cn${path}`; // 保留路由路径
}

if (lng === 'en' && window.location.href.includes('deepbi.cn')) {
  const path = window.location.hash; // 获取当前的hash路径
  window.location.href = `https://www.deepbi.com${path}`; // 保留路由路径
}
createRoot(document.getElementById('root')).render(<App />);